import React from "react";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight, MdDownload, MdFilterList } from "react-icons/md";
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

const MonthlyData = () => {
    const navigate = useNavigate();

    const data = [
        { name: 'CP300I', value: 200 },
        { name: 'CP3C', value: 300 },
        { name: 'CP3V', value: 500 },
    ];

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p onClick={() => navigate("/financial")} className="text-[#828282] cursor-pointer">Financials</p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">January Financial Data</p>
                </div>

                {/* main content */}
                <p className="mt-5 text-center text-[20px] font-bold underline underline-offset-[5px]">
                    January Financial Data
                </p>
                <div className="flex justify-end">
                    <div
                        onClick={() => navigate("/financial/invoice")}
                        className="flex items-center gap-x-2 bg-[#00C2FFCC] px-4 py-2 w-fit cursor-pointer rounded"
                    >
                        <p className="text-[14px] text-white">Invoice</p>
                        <MdDownload className="text-[16px] text-white" />
                    </div>
                </div>
                <ResponsiveContainer width="20%" height="30%" className=" float-right mt-5">
                    <PieChart width={400} height={400}>
                        <Pie
                            data={data}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            isAnimationActive={false}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>

                <div className="mt-10 ml-6">
                    <div className="flex items-center gap-x-3 text-[14px] mt-5">
                        <p className="text-[18px] font-bold underline underline-offset-[5px]">Expenditure Breakdown</p>
                        <MdFilterList className="text-[18px] text-[#6c6767]" />
                    </div>
                    <div className="flex items-center gap-x-3 text-[14px] mt-5">
                        <p className="text-[14px]">Total :</p>
                        <p className="text-[14px] font-bold">$ 500</p>
                    </div>
                    <div className="flex items-center gap-x-3 text-[14px] mt-2">
                        <p className="text-[14px]">CP3V :</p>
                        <p className="text-[14px] font-bold">$ 130</p>
                    </div>
                    <div className="flex items-center gap-x-3 text-[14px] mt-2">
                        <p className="text-[14px]">CP3000 impression :</p>
                        <p className="text-[14px] font-bold">$ 220</p>
                    </div>
                    <div className="flex items-center gap-x-3 text-[14px] mt-2">
                        <p className="text-[14px]">CP3C :</p>
                        <p className="text-[14px] font-bold">$ 50</p>
                    </div>
                </div>

                <div className="mt-10 ml-6">
                    <p className="text-[18px] font-bold underline underline-offset-[5px]">Cost Per Advert</p>
                    <div className="flex items-center gap-x-3 text-[14px] mt-5">
                        <p className="text-[14px]">Vanilla Advert :</p>
                        <p className="text-[14px] font-bold">$ 230</p>
                    </div>
                    <div className="flex items-center gap-x-3 text-[14px] mt-2">
                        <p className="text-[14px]">Ice-Cream :</p>
                        <p className="text-[14px] font-bold">$ 100</p>
                    </div>
                    <div className="flex items-center gap-x-3 text-[14px] mt-2">
                        <p className="text-[14px]">Cookies :</p>
                        <p className="text-[14px] font-bold">$ 25</p>
                    </div>
                    <div className="flex items-center gap-x-3 text-[14px] mt-2">
                        <p className="text-[14px]">Chocolate :</p>
                        <p className="text-[14px] font-bold">$ 75</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MonthlyData