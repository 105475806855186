import React from "react";
import { GoHomeFill } from "react-icons/go";
import { useNavigate, useParams } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { yourAdvertData } from "../../data";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const BidAndPostView = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const draftPost = yourAdvertData.find(post => post.id === parseInt(id));

    const data = [
        { name: 'Mon', uv: 4000, pv: 2400, amt: 2400 },
        { name: 'Tue', uv: 3000, pv: 1398, amt: 2210 },
        { name: 'Wed', uv: 2000, pv: 9800, amt: 2290 },
        { name: 'Thu', uv: 2780, pv: 3908, amt: 2000 },
        { name: 'Fri', uv: 1890, pv: 4800, amt: 2181 },
        { name: 'Sat', uv: 2390, pv: 3800, amt: 2500 },
        { name: 'Sun', uv: 3490, pv: 4300, amt: 2100 },
    ];

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p onClick={() => navigate("/your-advert")} className="text-[#828282] cursor-pointer">Your Adverts</p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">{draftPost.name} Adverts</p>
                </div>

                {/* main content */}
                <div className="grid grid-cols-12 items-end">
                    <div className="col-span-4" />
                    <div className="col-span-4">
                        <p className="text-[24px] font-bold text-center mt-5">
                            {draftPost.name} Adverts
                        </p>
                        <p className="text-center text-[#635c5c] text-[12px] mt-1">{draftPost.created}</p>
                    </div>
                    <div className="col-span-4 flex justify-end">
                        <button className="text-[14px] px-4 py-[6px] text-[#ffffff] rounded bg-[#00AB07]">
                            Edit Advert
                        </button>
                    </div>
                </div>

                <div className="grid grid-cols-12 gap-5 mt-5">
                    <div className="col-span-6">
                        <p className="text-[16px] font-bold mb-5 bg-[#d9d9d9] px-3 py-1 rounded w-fit">
                            Advertisement Performance
                        </p>
                        <p className="text-[14px] mb-[6px]">Daily Average Interactions : 720</p>
                        <p className="text-[14px] mb-[10px]">Total Interactions : 7,429</p>
                        <div className="flex items-center gap-x-3">
                            <p className="text-[18px] font-bold">Bidding Cost : </p>
                            <p className="text-[18px] font-bold text-[#640D5F]">$750</p>
                        </div>
                    </div>
                    <div className="col-span-6 flex items-start justify-end">
                        <p className="px-10 py-5 text-[18px] font-bold rounded bg-[#00AB07] text-center w-fit text-[#ffffff]">
                            In - Depth panel of <br /> Performance
                        </p>
                    </div>
                    <div className="col-span-9">
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart
                                width={500}
                                height={300}
                                data={data}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
                                <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                    <div className="col-span-3">
                        <p className="text-[14px] font-bold mb-2 bg-[#d9d9d9] px-3 py-1 rounded w-full text-center">
                            Advert Preview
                        </p>
                        <img
                            alt={draftPost.name}
                            src={draftPost.image}
                            className="w-full h-[20rem] object-cover rounded"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BidAndPostView