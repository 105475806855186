import React from "react";
import { GoHomeFill } from "react-icons/go";
import { useNavigate, useParams } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { yourAdvertData } from "../../data";
import { IoClose } from "react-icons/io5";

const InactiveAdvertSucess = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const draftPost = yourAdvertData.find(post => post.id === parseInt(id));

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p onClick={() => navigate("/your-advert")} className="text-[#828282] cursor-pointer">Your Adverts</p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p onClick={() => navigate("/your-advert/inactive")} className="text-[#828282] cursor-pointer">Inactive Adverts</p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Repost Drafted</p>
                </div>

                {/* main content */}
                <div className="grid grid-cols-12 mt-[5rem]">
                    <div className="col-span-4" />
                    <div className="col-span-4 mx-10 border border-black py-5 px-8 rounded">
                        <div className="flex justify-end">
                            <IoClose
                                onClick={() => navigate("/your-advert")}
                                className="text-[24px] cursor-pointer"
                            />
                        </div>
                        <p className="text-[18px] font-bold text-[#0DD315]">
                            Congratulations! Your Advert “{draftPost.name} Advert” has been reposted!
                        </p>
                        <p className="mt-5 text-[14px]">Here are the current bidding settings :</p>
                        <p className="mt-2 text-[14px]">Advert Reserve Amount :</p>
                        <p className="mt-2 text-[14px]">Maximum Bid Price :</p>
                        <p className="mt-2 text-[14px]">Maximum Price Per Interaction :</p>

                        <div className="flex justify-between items-center mt-10">
                            <button
                                onClick={() => navigate("/your-advert")}
                                className="text-[14px] px-5 py-1 bg-[#0DD315] text-white rounded"
                            >
                                View Advert
                            </button>
                            <button
                                // onClick={() => navigate("/your-advert")}
                                className="text-[14px] px-5 py-1 bg-[#000000] text-white rounded"
                            >
                                Change Bid
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default InactiveAdvertSucess