import React from "react";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";

const CookiesPolicy = () => {
    const navigate = useNavigate();

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#828282] cursor-pointer" onClick={() => navigate("/settings")}>Settings</p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Cookies Policy</p>
                </div>

                {/* main content */}
                <div className="mt-5 text-gray-700">
                    <h1 className="text-2xl font-bold text-gray-800 mb-4">Cookies Policy</h1>
                    <p className="mt-4 text-sm text-gray-600">
                        This Cookies Policy explains what cookies are, how we use cookies, how third-parties we may partner with may use cookies on the Service, your choices regarding cookies, and further information about cookies.
                    </p>

                    <h2 className="mt-4 text-xl font-semibold text-gray-800">What are cookies?</h2>
                    <p className="mt-2 text-sm text-gray-600">
                        Cookies are small pieces of text sent by your web browser by a website you visit. A cookie file is stored in your web browser and allows the Service or a third-party to recognize you and make your next visit easier and the Service more useful to you.
                    </p>

                    <h2 className="mt-4 text-xl font-semibold text-gray-800">How we use cookies?</h2>
                    <p className="mt-2 text-sm text-gray-600">
                        When you use and access the Service, we may place a number of cookies files in your web browser. We use cookies for the following purposes: to enable certain functions of the Service, to provide analytics, to store your preferences, to enable advertisements delivery, including behavioral advertising.
                    </p>

                    <h2 className="mt-4 text-xl font-semibold text-gray-800">Types of cookies we use</h2>
                    <div className="mt-2 text-sm text-gray-600">
                        <ul className="list-disc pl-5">
                            <li><strong>Session Cookies:</strong> We use Session Cookies to operate our Service.</li>
                            <li><strong>Preference Cookies:</strong> We use Preference Cookies to remember your preferences and various settings.</li>
                            <li><strong>Security Cookies:</strong> We use Security Cookies for security purposes.</li>
                            <li><strong>Advertising Cookies:</strong> Advertising Cookies are used to serve you with advertisements that may be relevant to you and your interests.</li>
                            <li><strong>Analytics Cookies:</strong> Analytics Cookies are used to track information about how the Service is used so that we can make improvements. We also use Analytics Cookies to test new advertisements, pages, features or new functionality of the Service to see how our users react to them.</li>
                        </ul>
                    </div>

                    <h2 className="mt-4 text-xl font-semibold text-gray-800">List of cookies we use</h2>
                    <div className="mt-2 text-sm text-gray-600">
                        <table className="min-w-full bg-white">
                            <thead className="bg-[#cbc6c6]">
                                <tr>
                                    <th className="border py-2 text-gray-800">Cookie Name</th>
                                    <th className="border py-2 text-gray-800">Purpose</th>
                                    <th className="border py-2 text-gray-800">Expiration</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border px-4 py-2">session_id</td>
                                    <td className="border px-4 py-2">Used to maintain the session of the user.</td>
                                    <td className="border px-4 py-2">Session</td>
                                </tr>
                                <tr>
                                    <td className="border px-4 py-2">user_pref</td>
                                    <td className="border px-4 py-2">Stores user preferences.</td>
                                    <td className="border px-4 py-2">1 year</td>
                                </tr>
                                <tr>
                                    <td className="border px-4 py-2">security_token</td>
                                    <td className="border px-4 py-2">Used for security purposes.</td>
                                    <td className="border px-4 py-2">6 months</td>
                                </tr>
                                <tr>
                                    <td className="border px-4 py-2">ad_id</td>
                                    <td className="border px-4 py-2">Used to deliver relevant advertisements.</td>
                                    <td className="border px-4 py-2">1 month</td>
                                </tr>
                                <tr>
                                    <td className="border px-4 py-2">analytics_id</td>
                                    <td className="border px-4 py-2">Used to track user behavior for analytics purposes.</td>
                                    <td className="border px-4 py-2">2 years</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <h2 className="mt-4 text-xl font-semibold text-gray-800">Your choices regarding cookies</h2>
                    <p className="mt-2 text-sm text-gray-600">
                        If you'd like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of your web browser. However, please note that if you delete cookies or refuse to accept them, you might not be able to use all of the features we offer, you may not be able to store your preferences, and some of our pages might not display properly.
                    </p>

                    <h2 className="mt-4 text-xl font-semibold text-gray-800">More information about cookies</h2>
                    <p className="mt-2 text-sm text-gray-600">
                        You can learn more about cookies and the following third-party websites:
                    </p>
                    <ul className="list-disc pl-5 mt-2 text-sm text-gray-600">
                        <li><a href="https://www.allaboutcookies.org/" className="text-blue-500" target="_blank" rel="noopener noreferrer">AllAboutCookies: www.allaboutcookies.org</a></li>
                        <li><a href="https://www.networkadvertising.org/" className="text-blue-500" target="_blank" rel="noopener noreferrer">Network Advertising Initiative: www.networkadvertising.org</a></li>
                    </ul>
                </div>

                {/* Footer */}
                <div className="mt-10 border-t pt-6 text-gray-500 text-sm">
                    <p>&copy; 2024 KAS. All rights reserved.</p>
                </div>
            </div>
        </div>
    )
}

export default CookiesPolicy;