import React from "react";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { invoiceData } from "../../data"

const Invoice = () => {
    const navigate = useNavigate();

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p onClick={() => navigate("/financial")} className="text-[#828282] cursor-pointer">Financials</p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p onClick={() => navigate("/financial/monthly-data")} className="text-[#828282] cursor-pointer">January Financial Data</p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Download Invoice</p>
                </div>

                {/* main content */}
                <p className="text-[24px] font-bold underline underline-offset-[5px] text-center mt-5">
                    January Invoice
                </p>

                <div className="grid grid-cols-12 gap-x-5">
                    <div className="col-span-3">
                        <div className="mt-[7rem]">
                            <p className="bg-[#d9d9d9] text-[#000000] py-2 w-full rounded text-center">
                                Company Name
                            </p>
                            <p className="bg-[#d9d9d9] text-[#000000] py-2 w-full rounded text-center mt-2">
                                Tier of Company
                            </p>
                            <p className="bg-[#d9d9d9] text-[#000000] py-2 w-full rounded text-center mt-2">
                                Location
                            </p>
                            <p className="bg-[#d9d9d9] text-[#000000] py-2 w-full rounded text-center mt-2">
                                Phone Number
                            </p>
                            <p className="bg-[#d9d9d9] text-[#000000] py-2 w-full rounded text-center mt-2">
                                Billed To: Company Name
                            </p>
                            <p className="bg-[#d9d9d9] text-[#000000] py-2 w-full rounded text-center mt-2">
                                Location Address
                            </p>
                            <div className="flex items-center justify-center gap-x-2 mt-4">
                                <p className="text-[16px] font-bold">Total Billed :</p>
                                <p className="text-[16px] font-bold text-[#0DD315]">$ 2,156</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-9">
                        <div className="flex items-center justify-between mt-14">
                            <button className="text-[14px] bg-[#00AB07] text-[#ffffff] py-2 w-[15rem] rounded">
                                Download
                            </button>
                            <button className="text-[14px] bg-[#D9D9D9] text-[#000000] py-2 w-[15rem] rounded">
                                Print
                            </button>
                            <button className="text-[14px] bg-[#00C2FFCC] text-[#ffffff] py-2 w-[15rem] rounded">
                                Invoice #5b1000tas123
                            </button>
                        </div>
                        <p className="text-[18px] py-6 text-center font-bold border-t border-l border-r border-[#828282] mt-5">
                            Breakdown
                        </p>
                        <table className="border-collapse border-[#969696] mt-0 w-full text-center">
                            <thead>
                                <tr className="text-[#000000]">
                                    <th className="p-3 bg-[#ababab] border border-[#828282]">Advert Name</th>
                                    <th className="bg-[#ababab] border border-[#828282]">Date</th>
                                    <th className="bg-[#ababab] border border-[#828282]">Ad Type</th>
                                    <th className="bg-[#ababab] border border-[#828282]">Total Spend ($)</th>
                                    <th className="bg-[#ababab] border border-[#828282]">Ad Revenue ($)</th>
                                    <th className="bg-[#ababab] border border-[#828282]">Max Bid Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoiceData.map((data, index) => (
                                    <tr key={index}>
                                        <td className="p-3 border border-[#828282]">{data.advertName}</td>
                                        <td className="border border-[#828282]">{data.date}</td>
                                        <td className="border border-[#828282]">{data.adType}</td>
                                        <td className="border border-[#828282]">{data.totalSpend}</td>
                                        <td className="border border-[#828282]">{data.adRevenue}</td>
                                        <td className="border border-[#828282]">{data.maxBidPrice}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Invoice