import React, { useState } from "react";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import StageOne from "./createadvert/StageOne";
import StageTwo from "./createadvert/StageTwo";
import StageThree from "./createadvert/StageThree";
import StageFour from "./createadvert/StageFour";
import StageFive from "./createadvert/StageFive";
import { MdKeyboardArrowRight } from "react-icons/md";
import { IoMdArrowRoundForward } from "react-icons/io";
import cartoon_art from "../../assets/createadvert/create_advert_artwork.png";

const CreateAdvert = () => {
    const navigate = useNavigate();
    const [stage, setStage] = useState(1);

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Create Adverts</p>
                </div>

                {/* main content */}
                <p className="text-[18px] font-bold mt-5">Create New Advert</p>
                <div className="px-4 py-2 border border-[#c2c1c1] rounded w-fit mt-2">
                    <p className="text-[13px] font-bold">Business : Anton’s Cookies</p>
                    <p className="text-[13px] font-bold mt-1">Tier : Tier 3</p>
                </div>

                {/* stages */}
                {stage === 1 && <StageOne />}
                {stage === 2 && <StageTwo />}
                {stage === 3 && <StageThree />}
                {stage === 4 && <StageFour />}
                {stage === 5 && <StageFive />}

                {/* buttons */}
                <div className="flex items-center justify-between mt-8 mr-[5rem]">
                    <img
                        alt="cartoon_art"
                        src={cartoon_art}
                        className="h-[6rem]"
                    />
                    <div className="flex flex-col items-end">
                        {stage <= 2 &&
                            <div className="flex items-center gap-x-2 mb-5">
                                <p className="text-[14px] font-bold text-[#a2a1a1]">
                                    {stage === 1 && "Step 5/7"}
                                    {stage === 2 && "step 8/9"}
                                </p>
                                <IoMdArrowRoundForward className="text-[18px] text-teal-400 cursor-pointer" />
                            </div>
                        }
                        <div className="flex items-center gap-x-5">
                            {stage > 1 && (
                                <button
                                    onClick={() => setStage(stage - 1)}
                                    className="bg-[#000000] text-white px-3 text-[14px] py-2 rounded w-[7rem]"
                                >
                                    Back
                                </button>
                            )}
                            {stage === 5 && (
                                <button
                                    // onClick={}
                                    className="bg-[#000000] text-white px-3 text-[14px] py-2 rounded w-[7rem]"
                                >
                                    Draft Advert
                                </button>
                            )}
                            {(stage === 1 || stage === 2 || stage === 3 || stage === 4 || stage === 5) && (
                                <button
                                    onClick={() => {
                                        if (stage < 5) {
                                            setStage(stage + 1);
                                        } else if (stage === 5) {
                                            navigate("/one-cent");
                                        }
                                    }}
                                    className="bg-[#00AB07] text-white px-3 text-[14px] py-2 rounded w-[7rem]"
                                >
                                    {stage === 5 ? "Create Advert" : "Continue"}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateAdvert