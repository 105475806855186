import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GoHomeFill } from "react-icons/go";
import { MdKeyboardArrowRight } from "react-icons/md";
import { IoSearchSharp } from "react-icons/io5";

const ApplicationStatus = () => {
    const navigate = useNavigate();
    const [date, setDate] = useState("");
    const [type, setType] = useState("");

    const applications = [
        {
            name: "Tom’s Hot Chocolate",
            status: "Pending",
        },
        {
            name: "Nike Shoe Store",
            status: "Accepted",
        },
    ];

    const getStatusClass = (status) => {
        switch (status) {
            case "Sent":
                return "bg-[#00AB07]";
            case "Pending":
                return "bg-[#e62d2d]";
            case "Accepted":
                return "bg-[#00AB07]";
            default:
                return "bg-[#d9d9d9]";
        }
    };

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold p-2">
                    <div
                        onClick={() => navigate("/")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p
                        onClick={() => navigate("/team-up")}
                        className="text-[#828282] cursor-pointer"
                    >
                        Team - Up
                    </p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Application</p>
                </div>

                {/* application status */}
                <div className="flex items-center justify-center mt-10 relative border-b border-[#d9d9d9] pb-12">
                    <div className="relative">
                        <input
                            type="text"
                            placeholder="Search for Application"
                            className="bg-[#ffffff] pl-8 pr-2 py-2 w-[30rem] border border-black text-[13px] rounded-full focus:outline-none"
                        />
                        <div className="absolute top-1/2 left-2 transform -translate-y-1/2">
                            <IoSearchSharp
                                className="text-[20px] text-[#000000] hover:text-[#f65d5d] cursor-pointer"
                            />
                        </div>
                    </div>
                    <div className="absolute right-2 top-0">
                        <div className="relative">
                            <select
                                required
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                                className="w-[10rem] bg-[#d9d9d9] px-3 py-[6px] text-black text-[14px] border border-[#ffffff] focus:outline-none appearance-none rounded"
                            >
                                <option value={""}>Date</option>
                                <option value="Today">Today</option>
                                <option value="Last Week">Last Week</option>
                                <option value="Last Month">Last Month</option>
                            </select>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.2" stroke="currentColor" className="h-4 w-4 ml-1 absolute top-2.5 right-2.5 text-slate-700 text-black">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                            </svg>
                        </div>
                        <div className="relative mt-1">
                            <select
                                required
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                                className="w-[10rem] bg-[#d9d9d9] px-3 py-[6px] text-black text-[14px] border border-[#ffffff] focus:outline-none appearance-none rounded"
                            >
                                <option value="Most Recent">Most Recent</option>
                                <option value="Older">Older</option>
                            </select>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.2" stroke="currentColor" className="h-4 w-4 ml-1 absolute top-2.5 right-2.5 text-slate-700 text-black">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                            </svg>
                        </div>
                    </div>
                </div>

                {applications.map((app, index) => (
                    <div key={index} className={`p-2 flex flex-col justify-center items-center ${index === 0 ? 'mt-5' : 'mt-14'}`}>
                        <div className="grid grid-cols-12 items-center w-[50%] gap-5">
                            <div className="col-span-7 flex items-center justify-start">
                                <p className="font-bold text-[14px] line-clamp-1">Application To : {app.name}</p>
                            </div>
                            <div className="col-span-5 flex items-center justify-end gap-x-2">
                                <button className="text-[14px] text-white bg-black py-2 rounded w-[10rem] ml-8">
                                    Cancel
                                </button>
                                <button className="text-[14px] text-white bg-[#00AB07] py-2 rounded w-[10rem] ml-3">
                                    Send Message
                                </button>
                            </div>
                        </div>
                        <div className="flex items-center justify-center mt-6">
                            <div className="relative flex items-center">
                                <div className="absolute w-full h-[3px] bg-[#c2c1c1]"></div>
                                <div className={`relative z-10 flex items-center justify-center w-6 h-6 ${getStatusClass("Sent")} rounded-full`}>
                                    <span className="text-black text-[12px] font-bold mt-12">Sent</span>
                                </div>
                                <div className={`relative z-10 flex items-center justify-center w-6 h-6 ${getStatusClass(app.status)} rounded-full mx-[19.5rem]`}>
                                    <span className="text-black text-[12px] font-bold mt-12">{app.status === "Accepted" ? "" : `${app.status}`}</span>
                                </div>
                                <div className={`relative z-10 flex items-center justify-center w-6 h-6 ${getStatusClass(app.status === "Accepted" ? "Accepted" : "")} rounded-full`}>
                                    <span className="text-black text-[12px] font-bold mt-12">{app.status === "Accepted" ? "Accepted" : ""}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ApplicationStatus;