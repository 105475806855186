import React from "react";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";

const ContactUs = () => {
    const navigate = useNavigate();

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Contact Us</p>
                </div>

                {/* main content */}
                <p className="mt-5 text-center text-[20px] font-bold underline underline-offset-[5px]">
                    Contact Us
                </p>
                <div className="mt-10">
                    <div className="flex items-center justify-center gap-x-5">
                        <p className="text-[14px]">K.A.S Email: </p>
                        <p className="text-[14px] py-2 px-10 rounded w-fit bg-[#d9d9d9]">abc@example.com</p>
                    </div>

                    <div className="flex items-center justify-center mt-5">
                        <div className="w-[50%] border border-[#949090] p-10 rounded">
                            <div className="grid grid-cols-12 items-center gap-x-3">
                                <div className="col-span-5">
                                    <p>Choose the following Issue:</p>
                                </div>
                                <div className="col-span-7">
                                    <input
                                        type="text"
                                        className="w-full border-none bg-[#d1cdcd] focus:outline-none p-2 rounded"
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-12 items-center gap-x-3 mt-5">
                                <div className="col-span-5">
                                    <p>Description:</p>
                                </div>
                                <div className="col-span-7">
                                    <textarea
                                        rows={5}
                                        className="w-full border-none bg-[#d1cdcd] focus:outline-none p-2 rounded resize-none"
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-12 items-center gap-x-3 mt-4">
                                <div className="col-span-5">
                                    <p>Your email:</p>
                                </div>
                                <div className="col-span-7">
                                    <input
                                        type="text"
                                        className="w-full border-none bg-[#d1cdcd] focus:outline-none p-2 rounded"
                                    />
                                </div>
                            </div>
                            <div className="flex items-center justify-center mt-8">
                                <button className="text-[14px] bg-[#0DD315] text-[#ffffff] px-4 py-2 rounded">
                                Send Message
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs