import React, { useState } from "react";
import FilterSearch from "./FilterSearch";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { IoSearchSharp, IoClose } from "react-icons/io5";

const TeamUpSearch = () => {
    const navigate = useNavigate();
    const [inputValue, setInputValue] = useState("Nike");

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p
                        onClick={() => navigate("/team-up")}
                        className="text-[#828282] cursor-pointer"
                    >
                        Team - Up
                    </p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Team - Up Search</p>
                </div>

                {/* search section */}
                <div className="flex items-start justify-between mt-5">
                    <div className="flex flex-col">
                        <div className="flex items-center">
                            <p className="text-[24px] font-bold">Team - Up</p>
                            <p className="text-[12px] text-[#000000] ml-[5rem] bg-[#d9d9d9] px-3 py-[6px] font-bold rounded-md cursor-pointer">
                                Your Team - Up Profile
                            </p>
                            <p className="text-[12px] text-[#000000] ml-[5rem] bg-[#d9d9d9] px-3 py-[6px] font-bold rounded-md cursor-pointer">
                                Your Team - Up Adverts
                            </p>
                        </div>
                        <div className="relative mt-3">
                            <input
                                type="text"
                                placeholder="Search for Team-Up"
                                value={inputValue}
                                onClick={() => navigate("/team-up/search")}
                                onChange={(e) => setInputValue(e.target.value)}
                                className="bg-[#ffffff] pl-2 pr-8 py-2 w-full border border-black text-[13px] rounded focus:outline-none"
                            />
                            <div className="absolute top-1/2 right-2 transform -translate-y-1/2">
                                {inputValue.length > 1 ? (
                                    <IoClose
                                        className="text-[20px] text-[#000000] hover:text-[#f65d5d] cursor-pointer"
                                        onClick={() => setInputValue("")}
                                    />
                                ) : (
                                    <IoSearchSharp className="text-[20px] text-[#000000] cursor-pointer" />
                                )}
                            </div>
                        </div>
                        <div className="border border-black mt-5 rounded">
                            <p className="text-[14px] text-[#000000] underline underline-offset-[4px] font-bold p-2">
                                Search Results
                            </p>
                            <div className="grid grid-cols-12 items-center gap-x-3 px-2 py-1">
                                <div className="col-span-11">
                                    <p>Nike Shoe Advertiser</p>
                                </div>
                                <div className="col-span-1 flex justify-end">
                                    <IoClose className="text-[20px] cursor-pointer hover:text-[#f65d5d]" />
                                </div>
                            </div>
                            <div className="grid grid-cols-12 items-center gap-x-3 px-2 py-1">
                                <div className="col-span-11">
                                    <p>Nike Shoe Advertiser</p>
                                </div>
                                <div className="col-span-1 flex justify-end">
                                    <IoClose className="text-[20px] cursor-pointer hover:text-[#f65d5d]" />
                                </div>
                            </div>
                            <div className="grid grid-cols-12 items-center gap-x-3 px-2 py-1">
                                <div className="col-span-11">
                                    <p>Nike Shoe Advertiser</p>
                                </div>
                                <div className="col-span-1 flex justify-end">
                                    <IoClose className="text-[20px] cursor-pointer hover:text-[#f65d5d]" />
                                </div>
                            </div>
                            <p className="text-[14px] text-[#000000] underline underline-offset-[4px] font-bold p-2 mt-2">
                                Trending Searches
                            </p>
                            <div className="flex items-center gap-x-3 px-2 py-1">
                                <input 
                                    type="checkbox"
                                    className="h-[14px] w-[14px] accent-[#00AB07] cursor-pointer"
                                />
                                <p>Wallmart</p>
                            </div>
                            <div className="flex items-center gap-x-3 px-2 py-1">
                                <input 
                                    type="checkbox"
                                    className="h-[14px] w-[14px] accent-[#00AB07] cursor-pointer"
                                />
                                <p>McDonald's</p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-0">
                        <FilterSearch />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeamUpSearch