import React from "react";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";

const ApplicationSubmit = () => {
    const navigate = useNavigate();

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Team - Up</p>
                </div>

                {/* application submit */}
                <div className="flex mt-14 w-full justify-center">
                    <div className="border border-black rounded p-5 w-[25%]">
                        <p className="text-[20px] font-bold text-[#00AB07] text-center">
                            Your Team - Up Application has been sent to : Tom’s Hot Chocolate!
                        </p>
                        <p className="text-[12px] mt-2 text-[#afaeae] text-center">
                            Check your Application status page for more!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApplicationSubmit