import React, { useState } from "react";
import { motion } from "framer-motion";
import { CiLogout } from "react-icons/ci";
import { IoSearchSharp } from "react-icons/io5";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { FaHandPaper, FaUser } from "react-icons/fa";
import { BsFillQuestionDiamondFill } from "react-icons/bs";
import { FaCirclePlus, FaCaretDown } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";

const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [dropDownMenu, setDropDownMenu] = useState("Advertisers Business");
    const [showDropDownMenu, setShowDropDownMenu] = useState(false);

    const headerMenuItems = [
        { id: 1, name: "Account Balance: $10,000", pathname: "/account", icon: FaMoneyCheckAlt },
        { id: 2, name: "Create Adverts", pathname: "/create-advert", icon: FaCirclePlus },
        { id: 3, name: dropDownMenu, pathname: "", icon: FaCaretDown, isDropdown: true },
        { id: 4, name: "Keywords", pathname: "/keywords", icon: IoSearchSharp },
        { id: 5, name: "How It Works", pathname: "/how", icon: BsFillQuestionDiamondFill },
        { id: 6, name: "Help Mode", pathname: "/help-mode", icon: FaHandPaper },
    ];

    const dropdownItems = [
        { id: 1, name: "Menu One" },
        { id: 2, name: "Menu Two" },
        { id: 3, name: "Menu Three" },
    ];

    const dropdownVariants = {
        hidden: {
            opacity: 0,
            y: -10
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.3
            }
        }
    };

    const handleLogout = () => {
        sessionStorage.removeItem("userSession");
        navigate("/security-pass");
    };

    const isAuthPage = ["/login", "/signup", "/forgot-pass"].includes(location.pathname);

    return (
        <div className="flex items-center justify-between bg-[#D6EFD8] px-3 py-4 z-50 shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
            <p
                onClick={() => navigate("/")}
                className="px-8 py-2 bg-[#00AB07] text-[#ffffff] text-[20px] font-semibold cursor-pointer rounded"
            >
                K.A.S
            </p>

            {isAuthPage &&
                <div
                    onClick={() => navigate(location.pathname === "/login" ? "/signup" : "/login")}
                    className={`flex items-center gap-x-2 px-4 py-2 cursor-pointer rounded relative transition-colors duration-500 
                        ${location.pathname === "/login" ? "bg-[#ffffff] hover:bg-[#000000] hover:text-[#ffffff]" : "bg-[#000000] text-[#ffffff]"}
                    `}
                >
                    <FaUser className="text-[16px]" />
                    <p className="text-[14px]">{location.pathname === "/login" ? "Signup" : "Login"}</p>
                </div>
            }

            {!isAuthPage &&
                <div
                    onClick={() => navigate("/account")}
                    className={`flex items-center gap-x-2 px-4 py-1 cursor-pointer rounded relative transition-colors duration-500 
                        ${location.pathname === "/account" ? "bg-[#000000] text-[#ffffff]" : "bg-[#ffffff] hover:bg-[#000000] hover:text-[#ffffff]"}
                    `}
                >
                    <img
                        alt="Profile"
                        className="w-7 h-7 rounded-full"
                        src="https://images.unsplash.com/photo-1608541737042-87a12275d313?q=80&w=2061&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    />
                    <p className="text-[14px]">Nike Store</p>
                </div>
            }

            {headerMenuItems.map((item) => (
                <div
                    key={item.id}
                    onClick={() => {
                        if (!isAuthPage) {
                            item.isDropdown ? setShowDropDownMenu(!showDropDownMenu) : navigate(item.pathname);
                        }
                    }}
                    className={`flex items-center gap-x-2 px-4 py-2 cursor-pointer rounded relative transition-colors duration-500 
                        ${location.pathname === item.pathname ? "bg-[#000000] text-[#ffffff]" : "bg-[#ffffff] hover:bg-[#000000] hover:text-[#ffffff]"}
                    `}
                >
                    {item.icon && <item.icon className="text-[20px]" />}
                    <p className="text-[14px]">
                        {item.name}
                    </p>
                    {item.isDropdown && showDropDownMenu && (
                        <motion.div
                            initial="hidden"
                            animate="visible"
                            variants={dropdownVariants}
                            className="absolute top-11 left-0 w-full bg-black"
                        >
                            {dropdownItems.map((dropdownItem) => (
                                <motion.p
                                    key={dropdownItem.id}
                                    onClick={() => setDropDownMenu(dropdownItem.name)}
                                    whileHover={{
                                        backgroundColor: "#3d3c3c",
                                        scale: 1.02,
                                        transition: { duration: 0.2 }
                                    }}
                                    className="text-[13px] p-2 text-white border-b border-[#7d7b7b]"
                                >
                                    {dropdownItem.name}
                                </motion.p>
                            ))}
                        </motion.div>
                    )}
                </div>
            ))}

            {!isAuthPage &&
                <div
                    onClick={handleLogout}
                    className="flex items-center gap-x-1 px-5 py-2 bg-[#ffffff] text-[#000000] hover:bg-[#000000] hover:text-[#ffffff] cursor-pointer rounded transition-colors duration-300"
                >
                    <CiLogout className="text-[20px]" />
                    <p className="text-[14px]">Logout</p>
                </div>
            }
        </div>
    )
}

export default Header;