import React, { useState } from "react";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";

const QuickCreate = () => {
    const navigate = useNavigate();
    const [adType, setAdType] = useState("");
    const [location, setLocation] = useState("");
    const [language, setLanguage] = useState("");

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Quick Create</p>
                </div>

                {/* main content */}
                <p className="text-[16px] font-bold mt-3">
                    Type in a few details and our algorithm will generate an ad for you !
                </p>
                <div className="grid grid-cols-12 items-center gap-x-5 mt-10">
                    <div className="col-span-2">
                        <p className="text-[16px]">Business Name :</p>
                    </div>
                    <div className=" col-span-3">
                        <input
                            type="text"
                            placeholder="Business Name"
                            className="w-full border border-[#828282] p-2 rounded-md focus:outline-none"
                        />
                    </div>
                </div>
                <div className="grid grid-cols-12 items-center gap-x-5 mt-2">
                    <div className="col-span-2">
                        <p className="text-[16px]">Ad Type :</p>
                    </div>
                    <div className="col-span-3">
                        <div className="relative">
                            <select
                                required
                                value={adType}
                                onChange={(e) => setAdType(e.target.value)}
                                className="w-full bg-[#ffffff] px-3 py-[8px] text-black text-[14px] border border-[#828282] focus:outline-none appearance-none rounded-md"
                            >
                                <option value={""}>Select</option>
                                <option value="Today">Today</option>
                                <option value="Last Week">Last Week</option>
                                <option value="Last Month">Last Month</option>
                            </select>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.2" stroke="currentColor" className="h-4 w-4 ml-1 absolute top-2.5 right-2.5 text-slate-700 text-black">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-12 items-center gap-x-5 mt-2">
                    <div className="col-span-2">
                        <p className="text-[16px]">Location Targeting :</p>
                    </div>
                    <div className="col-span-3">
                        <div className="relative">
                            <select
                                required
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                                className="w-full bg-[#ffffff] px-3 py-[8px] text-black text-[14px] border border-[#828282] focus:outline-none appearance-none rounded-md"
                            >
                                <option value={""}>Select</option>
                                <option value="Today">Today</option>
                                <option value="Last Week">Last Week</option>
                                <option value="Last Month">Last Month</option>
                            </select>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.2" stroke="currentColor" className="h-4 w-4 ml-1 absolute top-2.5 right-2.5 text-slate-700 text-black">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-12 items-center gap-x-5 mt-2">
                    <div className="col-span-2">
                        <p className="text-[16px]">Language Targeting :</p>
                    </div>
                    <div className="col-span-3">
                        <div className="relative">
                            <select
                                required
                                value={language}
                                onChange={(e) => setLanguage(e.target.value)}
                                className="w-full bg-[#ffffff] px-3 py-[8px] text-black text-[14px] border border-[#828282] focus:outline-none appearance-none rounded-md"
                            >
                                <option value={""}>Select</option>
                                <option value="Today">Today</option>
                                <option value="Last Week">Last Week</option>
                                <option value="Last Month">Last Month</option>
                            </select>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.2" stroke="currentColor" className="h-4 w-4 ml-1 absolute top-2.5 right-2.5 text-slate-700 text-black">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-12 mt-5">
                    <div className="col-span-12">
                        <p className="text-[16px] font-bold">Brief description of business</p>
                    </div>
                    <div className="col-span-12 mt-2">
                        <textarea
                            rows={8}
                            placeholder="Brief description of business"
                            className="w-full border border-[#828282] p-2 rounded-md focus:outline-none resize-none"
                        />
                    </div>
                    <div className="col-span-12 flex justify-center">
                        <button className="text-[14px] text-white bg-[#0DD315] py-2 rounded w-[10rem] mt-5">
                            Create Advert
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuickCreate