import React from "react";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { TbArrowBigRightFilled } from "react-icons/tb";

import step1_img from "../assets/howitwork/step_1.png";
import step2_img from "../assets/howitwork/step_2.png";
import step3_img from "../assets/howitwork/step_3.png";
import step4_img from "../assets/howitwork/step_4.png";
import step5_img from "../assets/howitwork/step_5.png";
import step6_img from "../assets/howitwork/step_6.png";
import step7_img from "../assets/howitwork/step_7.png";
import step8_img from "../assets/howitwork/step_8.png";
import step9_img from "../assets/howitwork/step_9.png";

const HowItWork = () => {
    const navigate = useNavigate();

    const menuItem = [
        { id: 1, img: step1_img, step: "1", title: "Create Account and Set business" },
        { id: 2, img: step2_img, step: "2", title: "Add Keywords" },
        { id: 3, img: step3_img, step: "3", title: "Create an Advert" },
        { id: 4, img: step4_img, step: "4", title: "Bid on your Advert" },
        { id: 5, img: step5_img, step: "5", title: "Team Up with other Advertisers" },
        { id: 6, img: step6_img, step: "6", title: "View your Advert Performance" },
        { id: 7, img: step7_img, step: "7", title: "Create / Add your Objectives" },
        { id: 8, img: step8_img, step: "8", title: "Track / View your Financials" },
        { id: 9, img: step9_img, step: "9", title: "Sit back and watch user interactions grow" },
    ];

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">How it works</p>
                </div>

                {/* steps */}
                <div className="grid grid-cols-12 gap-5 mt-5">
                    {menuItem.map((data) => (
                        <div className="col-span-2" key={data.id}>
                            <p className="text-[20px] font-bold text-center">
                                Step {data.step}
                            </p>
                            <div className="border border-black w-full h-[12rem] rounded relative overflow-hidden">
                                <div className="absolute w-full h-full -z-10">
                                    <img
                                        src={data.img}
                                        alt="step 2"
                                        className="w-full h-full object-cover p-8"
                                    />
                                </div>
                                <div className="grid grid-cols-12 h-full z-10">
                                    <div className="col-span-5" />
                                    <div className="col-span-7 flex flex-col items-center justify-between bg-[#000000a3] px-2">
                                        <p className="text-[14px] text-[#ffffff] font-bold text-center mt-4">
                                            {data.title}
                                        </p>
                                        <TbArrowBigRightFilled 
                                            onClick={() => navigate("/how/steps")}
                                            className="text-[3rem] text-[#ffffff] mb-8 cursor-pointer" 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default HowItWork