import React, { useState } from "react";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { FaCircleChevronDown } from "react-icons/fa6";
import { MdKeyboardArrowRight } from "react-icons/md";
import { IoSearchSharp, IoClose } from "react-icons/io5";

const YourKeywords = () => {
    const navigate = useNavigate();
    const [inputValue, setInputValue] = useState("");
    const [keywords, setKeywords] = useState([
        { id: 1, name: "Cookies" },
        { id: 2, name: "Snacks" },
        { id: 3, name: "Tasty" },
        { id: 4, name: "Chocolate chip" },
        { id: 5, name: "Vanilla ice cream" },
        { id: 6, name: "Anton’s" },
        { id: 7, name: "Crunchy" }
    ]);
    const [nicheKeywords, setNicheKeywords] = useState([
        { id: 8, name: "Healthy" },
        { id: 9, name: "Organic" },
        { id: 10, name: "Gluten-Free" }
    ]);
    const [commonKeywords, setCommonKeywords] = useState([
        { id: 11, name: "Delicious" },
        { id: 12, name: "Yummy" },
        { id: 13, name: "Savory" }
    ]);
    const [showAllKeywords, setShowAllKeywords] = useState(false);

    const handleAddKeyword = (name) => {
        const newKeyword = { id: keywords.length + 1, name };
        setKeywords([...keywords, newKeyword]);
        setInputValue("");
    };

    const handleRemoveKeyword = (id) => {
        setKeywords(keywords.filter(keyword => keyword.id !== id));
    };

    const toggleShowAllKeywords = () => {
        setShowAllKeywords(!showAllKeywords);
    };

    const handleKeywordClick = (name) => {
        if (!keywords.some(keyword => keyword.name === name)) {
            handleAddKeyword(name);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && inputValue.trim() !== "") {
            handleAddKeyword(inputValue.trim());
        }
    };

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Your Keywords</p>
                </div>

                {/* content */}
                <div className="mt-5 border border-gray-300 rounded-lg p-3 shadow-lg">
                    <p className="text-[20px] mb-2 font-bold text-[#333]">What are Keywords ?</p>
                    <p className="text-[14px] text-gray-700">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                        dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                        Excepteur sint occaecat cupidatat, sunt in culpa qui officia deserunt
                        mollit anim id est laborum.
                    </p>
                </div>

                <div className="grid grid-cols-12 gap-2 mt-5">
                    <div className="col-span-12">
                        <p className="text-[24px] font-bold text-[#333]">
                            Your Keywords
                        </p>
                    </div>
                    <div className="col-span-4 border border-gray-300 rounded-lg p-4 shadow-lg bg-white">
                        {keywords.length === 0 ? (
                            <p className="text-center text-gray-500">No keywords available. Please search and add keywords.</p>
                        ) : (
                            keywords.slice(0, showAllKeywords ? keywords.length : 6).map((item) => (
                                <div className="flex justify-between items-center mb-2 p-2 bg-gray-100 rounded-lg" key={item.id}>
                                    <span className="text-[16px] text-gray-700">{item.name}</span>
                                    <IoClose className="text-[20px] text-gray-500 cursor-pointer hover:text-red-500" onClick={() => handleRemoveKeyword(item.id)} />
                                </div>
                            ))
                        )}
                        {keywords.length > 6 && (
                            <div className="flex items-center justify-center w-full mt-4">
                                <FaCircleChevronDown
                                    className={`text-[24px] text-gray-500 cursor-pointer hover:text-[#00AB07] transform transition-transform ${showAllKeywords ? 'rotate-180' : ''}`}
                                    onClick={toggleShowAllKeywords}
                                />
                            </div>
                        )}
                    </div>
                    <div className="col-span-8">
                        <div className="relative mb-4">
                            <input
                                type="text"
                                placeholder="Search Keywords...."
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                onKeyPress={handleKeyPress}
                                className="bg-white pl-4 pr-10 py-3 w-full border border-gray-300 text-[14px] rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-[#00AB07]"
                            />
                            <div className="absolute top-1/2 right-3 transform -translate-y-1/2">
                                {inputValue.length > 1 ? (
                                    <IoClose
                                        className="text-[20px] text-gray-500 hover:text-red-500 cursor-pointer"
                                        onClick={() => setInputValue("")}
                                    />
                                ) : (
                                    <IoSearchSharp className="text-[20px] text-gray-500 cursor-pointer" />
                                )}
                            </div>
                            {inputValue && (
                                <div className="absolute top-full left-0 w-full bg-white border border-gray-300 rounded-lg shadow-lg mt-1 z-10">
                                    {keywords.filter(keyword => keyword.name.toLowerCase().includes(inputValue.toLowerCase())).length === 0 ? (
                                        <div className="p-2 text-gray-500">No keywords found. Press Enter to add "{inputValue}".</div>
                                    ) : (
                                        keywords.filter(keyword => keyword.name.toLowerCase().includes(inputValue.toLowerCase())).map(filteredKeyword => (
                                            <div key={filteredKeyword.id} className="p-2 hover:bg-gray-100 cursor-pointer" onClick={() => handleAddKeyword(filteredKeyword.name)}>
                                                {filteredKeyword.name}
                                            </div>
                                        ))
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="grid grid-cols-12 gap-4">
                            <div className="col-span-6">
                                <p className="text-[16px] text-center font-bold text-[#333]">Your Niche Keywords</p>
                                <div className="border border-gray-300 rounded-lg p-4 h-[16.7rem] overflow-y-scroll shadow-lg bg-white">
                                    {nicheKeywords.map((item) => (
                                        <p key={item.id} className="w-fit text-[16px] text-gray-700 hover:text-[#00AB07] cursor-pointer" onClick={() => handleKeywordClick(item.name)}>
                                            {item.name}
                                        </p>
                                    ))}
                                </div>
                            </div>
                            <div className="col-span-6">
                                <p className="text-[16px] text-center font-bold text-[#333]">Your Common Keywords</p>
                                <div className="border border-gray-300 rounded-lg p-4 h-[16.7rem] overflow-y-scroll shadow-lg bg-white">
                                    {commonKeywords.map((item) => (
                                        <p key={item.id} className="w-fit text-[16px] text-gray-700 hover:text-[#00AB07] cursor-pointer" onClick={() => handleKeywordClick(item.name)}>
                                            {item.name}
                                        </p>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default YourKeywords;