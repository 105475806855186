import React from "react";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { TbArrowBigRightFilled } from "react-icons/tb";

import step1_img from "../../assets/howitwork/step_1.png";
import step2_img from "../../assets/howitwork/step_2.png";
import step3_img from "../../assets/howitwork/step_3.png";
import step4_img from "../../assets/howitwork/step_4.png";
import step5_img from "../../assets/howitwork/step_5.png";
import step6_img from "../../assets/howitwork/step_6.png";

const HowOneCentBidWork = () => {
    const navigate = useNavigate();

    const menuItem = [
        { id: 1, img: step1_img, step: "1", title: "Choose one of your Adverts to Bid on from the 1 Cent Bidding page." },
        { id: 2, img: step2_img, step: "2", title: "Input your Ad Reserve amount ($). This is your total Ad spend" },
        { id: 3, img: step3_img, step: "3", title: "Set your maximum Bid Cap ($). This is the maximum price you want to pay for the advertising spot." },
        { id: 4, img: step4_img, step: "4", title: "Set your Maximum Price per Interaction ($). This is how much you want to spend every time a user interacts with or views your ad. This can be as low as 1-Cent!" },
        { id: 5, img: step5_img, step: "5", title: "Place your Bid" },
        { id: 6, img: step6_img, step: "6", title: "Sit back and watch your business grow!" }
    ];

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p onClick={() => navigate("/one-cent")} className="text-[#828282] cursor-pointer">1 - Cent Bidding</p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">How 1 - Cent Bidding Works</p>
                </div>

                {/* main content */}
                <p className="text-[20px] font-bold mt-5">
                    How 1 - Cent Bidding Works
                </p>

                <div className="grid grid-cols-12 gap-5 mt-5">
                    {menuItem.map((data) => (
                        <div className="col-span-4" key={data.id}>
                            <p className="text-[20px] font-bold text-center">
                                Step {data.step}
                            </p>
                            <div className="border border-black w-full h-[14rem] rounded relative overflow-hidden">
                                
                                <div className="grid grid-cols-12 h-full z-10">
                                    <div className="col-span-5 flex items-center justify-center">
                                        <img
                                            src={data.img}
                                            alt="step 2"
                                            className="w-[80%] h-[80%] object-cover"
                                        />
                                    </div>
                                    <div className="col-span-7 flex flex-col items-center justify-between bg-[#000000a3] px-2">
                                        <p className="text-[14px] text-[#ffffff] font-bold text-center mt-4">
                                            {data.title}
                                        </p>
                                        <TbArrowBigRightFilled
                                            onClick={() => navigate("/how/steps")}
                                            className="text-[3rem] text-[#ffffff] mb-8 cursor-pointer"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default HowOneCentBidWork