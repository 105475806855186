import React, { Fragment } from "react";
import Login from "../pages/auth/Login";
import SignUp from "../pages/auth/SignUp";
import ForgotPass from "../pages/auth/ForgotPass";
import NotFound from "../pages/NotFound";
import Home from "../pages/Home";
import Support from "../components/Support";
import HowItWork from "../pages/HowItWork";
import YourKeywords from "../pages/YourKeywords";
import WorkSteps from "../pages/howitwork/WorkSteps";
import TeamUp from "../pages/TeamUp";
import TeamUpSearch from "../pages/teamup/TeamUpSearch";
import CurrentTeampUp from "../pages/teamup/CurrentTeampUp";
import YourTeamUpProfile from "../pages/teamup/YourTeamUpProfile";
import CurrentTeamUpDetails from "../pages/teamup/CurrentTeamUpDetails";
import PopularTeamUp from "../pages/teamup/PopularTeamUp";
import ApplicationStatus from "../pages/teamup/ApplicationStatus";
import ApplicationDetails from "../pages/teamup/ApplicationDetails";
import ApplicationSubmit from "../pages/teamup/ApplicationSubmit";
import TeamUpApplication from "../pages/teamup/TeamUpApplication";
import TeampUpMessage from "../pages/teamup/TeampUpMessage";
import TierSystem from "../pages/TierSystem";
import SimilarAdvertisers from "../pages/tiersystem/SimilarAdvertisers";
import HowTierSystemWork from "../pages/tiersystem/HowTierSystemWork";
import HigherTierAdvertiser from "../pages/tiersystem/HigherTierAdvertiser";
import TierIncreaseApplication from "../pages/tiersystem/TierIncreaseApplication";
import OneCentBidding from "../pages/OneCentBidding";
import OneCentBiddingDetails from "../pages/onecentbid/OneCentBiddingDetails";
import PreviousBid from "../pages/onecentbid/PreviousBid";
import HowOneCentBidWork from "../pages/onecentbid/HowOneCentBidWork";
import YourAdvert from "../pages/YourAdvert";
import DraftedAdvert from "../pages/youradvert/DraftedAdvert";
import InactiveAdvert from "../pages/youradvert/InactiveAdvert";
import InactiveAdvertSucess from "../pages/youradvert/InactiveAdvertSucess";
import BidAndPostView from "../pages/youradvert/BidAndPostView";
import QuickCreate from "../pages/youradvert/QuickCreate";
import CreateAdvert from "../pages/youradvert/CreateAdvert";
import Objectives from "../pages/Objectives";
import AddObjectives from "../pages/objectives/AddObjectives";
import YourTimeline from "../pages/objectives/YourTimeline";
import Performance from "../pages/Performance";
import Financials from "../pages/Financials";
import MonthlyData from "../pages/financial/MonthlyData";
import Invoice from "../pages/financial/Invoice";
import Settings from "../pages/Settings";
import ContactUs from "../pages/ContactUs";
import DeleteAccount from "../pages/DeleteAccount";
import HelpMode from "../pages/HelpMode";
import Account from "../pages/Account";
import PrivacyPolicy from "../pages/legal/PrivacyPolicy";
import TermsAndCondition from "../pages/legal/TermsAndCondition";
import CookiesPolicy from "../pages/legal/CookiesPolicy";
import EditAccount from "../pages/account/EditAccount";

import AdvertCollection from "../pages/onecentbid/AdvertCollection";
import CreateCollection from "../pages/onecentbid/CreateCollection";
import AppLayout from "../components/AppLayout";
import TestPage from "../pages/TestPage";
import PrivateRoute from "./PrivateRoute";
import SecurityPass from "../pages/auth/SecurityPass";
import ViewCollection from "../pages/onecentbid/ViewCollection";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const MainRoutes = () => {
    return (
        <Fragment>
            <p className="block xl:hidden text-lg font-semibold text-center">
                Please open this application on a laptop or desktop for the best experience.
            </p>
            {/* <div className="hidden xl:block"> */}
            <div>
                <Router>
                    <Routes>
                        <Route path="/security-pass" element={<SecurityPass />} />
                        <Route path="/" element={<PrivateRoute />}>
                            <Route path="/" element={<AppLayout><Home /></AppLayout>} />
                            <Route path="/login" element={<AppLayout><Login /></AppLayout>} />
                            <Route path="/signup" element={<AppLayout><SignUp /></AppLayout>} />
                            <Route path="/forgot-pass" element={<AppLayout><ForgotPass /></AppLayout>} />
                            <Route path="/account" element={<AppLayout><Account /></AppLayout>} />
                            <Route path="/account/edit" element={<AppLayout><EditAccount /></AppLayout>} />
                            <Route path="/how" element={<AppLayout><HowItWork /></AppLayout>} />
                            <Route path="/how/steps" element={<AppLayout><WorkSteps /></AppLayout>} />

                            <Route path="/team-up" element={<AppLayout><TeamUp /></AppLayout>} />
                            <Route path="/team-up/search" element={<AppLayout><TeamUpSearch /></AppLayout>} />
                            <Route path="/team-up/current" element={<AppLayout><CurrentTeampUp /></AppLayout>} />
                            <Route path="/team-up/current/:id" element={<AppLayout><CurrentTeamUpDetails /></AppLayout>} />
                            <Route path="/team-up/popular" element={<AppLayout><PopularTeamUp /></AppLayout>} />
                            <Route path="/team-up/application" element={<AppLayout><ApplicationStatus /></AppLayout>} />
                            <Route path="/team-up/application/view/:id" element={<AppLayout><ApplicationDetails /></AppLayout>} />
                            <Route path="/team-up/application/:id" element={<AppLayout><TeamUpApplication /></AppLayout>} />
                            <Route path="/team-up/application/submit" element={<AppLayout><ApplicationSubmit /></AppLayout>} />
                            <Route path="/team-up/message" element={<AppLayout><TeampUpMessage /></AppLayout>} />
                            <Route path="/team-up/profile" element={<AppLayout><YourTeamUpProfile /></AppLayout>} />

                            <Route path="/tier-system" element={<AppLayout><TierSystem /></AppLayout>} />
                            <Route path="/tier-system/similar-advertiser" element={<AppLayout><SimilarAdvertisers /></AppLayout>} />
                            <Route path="/tier-system/how-it-work" element={<AppLayout><HowTierSystemWork /></AppLayout>} />
                            <Route path="/tier-system/higher-advertiser" element={<AppLayout><HigherTierAdvertiser /></AppLayout>} />
                            <Route path="/tier-system/increase-application" element={<AppLayout><TierIncreaseApplication /></AppLayout>} />

                            <Route path="/one-cent" element={<AppLayout><OneCentBidding /></AppLayout>} />
                            <Route path="/one-cent/details/:id" element={<AppLayout><OneCentBiddingDetails /></AppLayout>} />
                            <Route path="/one-cent/previous-bid" element={<AppLayout><PreviousBid /></AppLayout>} />
                            <Route path="/one-cent/how-bid-work" element={<AppLayout><HowOneCentBidWork /></AppLayout>} />
                            <Route path="/one-cent/collection" element={<AppLayout><AdvertCollection /></AppLayout>} />
                            <Route path="/one-cent/collection" element={<AppLayout><AdvertCollection /></AppLayout>} />
                            <Route path="/one-cent/create-collection" element={<AppLayout><CreateCollection /></AppLayout>} />
                            <Route path="/one-cent/view-collection/:id" element={<AppLayout><ViewCollection /></AppLayout>} />

                            <Route path="/your-advert" element={<AppLayout><YourAdvert /></AppLayout>} />
                            <Route path="/your-advert/draft" element={<AppLayout><DraftedAdvert /></AppLayout>} />
                            <Route path="/your-advert/inactive" element={<AppLayout><InactiveAdvert /></AppLayout>} />
                            <Route path="/your-advert/inactive/repost-success/:id" element={<AppLayout><InactiveAdvertSucess /></AppLayout>} />
                            <Route path="/your-advert/post-view/:id" element={<AppLayout><BidAndPostView /></AppLayout>} />
                            <Route path="/quick-create" element={<AppLayout><QuickCreate /></AppLayout>} />
                            <Route path="/create-advert" element={<AppLayout><CreateAdvert /></AppLayout>} />

                            <Route path="/objectives" element={<AppLayout><Objectives /></AppLayout>} />
                            <Route path="/objectives/add" element={<AppLayout><AddObjectives /></AppLayout>} />
                            <Route path="/objectives/your-timeline" element={<AppLayout><YourTimeline /></AppLayout>} />
                            <Route path="/performance" element={<AppLayout><Performance /></AppLayout>} />

                            <Route path="/financial" element={<AppLayout><Financials /></AppLayout>} />
                            <Route path="/financial/monthly-data" element={<AppLayout><MonthlyData /></AppLayout>} />
                            <Route path="/financial/invoice" element={<AppLayout><Invoice /></AppLayout>} />

                            <Route path="/settings" element={<AppLayout><Settings /></AppLayout>} />
                            <Route path="/contact-us" element={<AppLayout><ContactUs /></AppLayout>} />
                            <Route path="/account/delete" element={<AppLayout><DeleteAccount /></AppLayout>} />
                            <Route path="/help-mode" element={<AppLayout><HelpMode /></AppLayout>} />
                            <Route path="/privacy-policy" element={<AppLayout><PrivacyPolicy /></AppLayout>} />
                            <Route path="/terms-and-condition" element={<AppLayout><TermsAndCondition /></AppLayout>} />
                            <Route path="/cookies-policy" element={<AppLayout><CookiesPolicy /></AppLayout>} />
                            <Route path="/keywords" element={<AppLayout><YourKeywords /></AppLayout>} />
                            <Route path="/test-page" element={<TestPage />} />
                            <Route path="*" element={<AppLayout><NotFound /></AppLayout>} />
                        </Route>
                    </Routes>
                    <Support />
                </Router>
            </div>
        </Fragment>
    )
}

export default MainRoutes;