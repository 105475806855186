import React, { useState } from "react";
import { GoHomeFill } from "react-icons/go";
import { oneCentBidData } from "../../data";
import { useNavigate, useParams } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";

const OneCentBiddingDetails = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const oneCentBidDetails = oneCentBidData.find(teamUp => teamUp.id === parseInt(id));
    const [bidPlaced, setBidPlaced] = useState(false);

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p onClick={() => navigate("/one-cent")} className="text-[#828282] cursor-pointer">1 - Cent Bidding</p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">{oneCentBidDetails.name} Advert</p>
                </div>

                {/* main content */}
                <p className="text-center bg-[#d9d9d9] text-[14px] font-bold py-2 w-[25%] mt-5 rounded">
                    {oneCentBidDetails.name} Advert
                </p>
                <div className="grid grid-cols-12 gap-5 mt-5">
                    <div className="col-span-4">
                        <img
                            alt="advert"
                            src={oneCentBidDetails.image}
                            className="w-[77%] h-full object-cover rounded"
                        />
                        <div className="flex items-center gap-x-4 mt-5">
                            <p className="text-[16px]">Tier :</p>
                            <p className="text-[16px] font-bold">1</p>
                        </div>
                        <div className="flex items-center gap-x-4 mt-1">
                            <p className="text-[16px]">CP3C :</p>
                            <p className="text-[16px] font-bold">2k</p>
                        </div>
                        <div className="flex items-center gap-x-4 mt-1">
                            <p className="text-[16px]">CP3000 impressions :</p>
                            <p className="text-[16px] font-bold">225K</p>
                        </div>
                        <div className="flex items-center gap-x-4 mt-1">
                            <p className="text-[16px]">CP3V :</p>
                            <p className="text-[16px] font-bold">352k</p>
                        </div>
                        <div className="flex items-center gap-x-4 mt-1">
                            <p className="text-[16px]">Expenditure :</p>
                            <p className="text-[16px] font-bold">$500</p>
                        </div>
                    </div>
                    <div className="col-span-4 ml-[-1rem] mt-[-2.6rem]">
                        <p className="text-[18px] font-bold py-2 text-center">
                            {bidPlaced ? "Your Bid" : "Bid"}
                        </p>
                        {!bidPlaced &&
                            <div className="grid grid-cols-12 gap-3 items-center border border-[#bcbcbc] p-4 rounded">
                                <div className="col-span-5">
                                    <p className="text-[13px]">Ad Reserve Amount ($):</p>
                                </div>
                                <div className="col-span-7">
                                    <input
                                        type="text"
                                        className="w-full px-2 py-2 text-[13px] bg-[#bcbcbc] focus:outline-none rounded"
                                    />
                                </div>
                                <div className="col-span-5">
                                    <p className="text-[13px]">Maximum Bid Price ($):</p>
                                </div>
                                <div className="col-span-7">
                                    <input
                                        type="text"
                                        className="w-full px-2 py-2 text-[13px] bg-[#bcbcbc] focus:outline-none rounded"
                                    />
                                </div>
                                <div className="col-span-5">
                                    <p className="text-[13px]">Maximum Price per Interaction ($) :</p>
                                </div>
                                <div className="col-span-7">
                                    <input
                                        type="text"
                                        className="w-full px-2 py-2 text-[13px] bg-[#bcbcbc] focus:outline-none rounded"
                                    />
                                </div>
                                <div className="col-span-12 flex items-center justify-center py-8">
                                    <button
                                        onClick={() => setBidPlaced(true)}
                                        className="px-6 py-2 text-[14px] text-[#ffffff] bg-[#00AB07] rounded"
                                    >
                                        Place bid
                                    </button>
                                </div>
                            </div>
                        }
                        {bidPlaced &&
                            <div className="p-5 rounded bg-[#5CDA61]" onClick={() => setBidPlaced(false)}>
                                <p className="text-center text-[#ffffff] text-[18px] font-bold">
                                    Congratulations! Your maximum bid interaction of $0.50
                                    has been placed! Your minimum interaction price has been set at $0.01.
                                </p>
                                <p className="text-center text-[#640D5F] text-[18px] font-bold pt-5">
                                    Your Order Number: #13024
                                </p>
                                <p className="text-center text-[12px] text-[#ffffff] font-bold pt-5">
                                    Oct 12, 2024 02:05 pm
                                </p>
                            </div>
                        }
                    </div>
                    <div className="col-span-4 ml-[4.5rem]">
                        <div className="flex items-center gap-x-4">
                            <p className="text-[16px]">Estimated  CP3C Increase :</p>
                            <p className="text-[16px] font-bold"></p>
                        </div>
                        <div className="flex items-center gap-x-4 mt-2">
                            <p className="text-[16px]">Estimated CP3000 impressions Increase :</p>
                            <p className="text-[16px] font-bold"></p>
                        </div>
                        <div className="flex items-center gap-x-4 mt-2">
                            <p className="text-[16px]">Estimated CP3V increase :</p>
                            <p className="text-[16px] font-bold"></p>
                        </div>
                        <div className="flex items-center gap-x-4 mt-2">
                            <p className="text-[16px]">Expenditure increase :</p>
                            <p className="text-[16px] font-bold"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OneCentBiddingDetails