import React from "react";
import SideMenu from "./SideMenu";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import SideMenuNonClickable from "./SideMenuNonClickable";

const AppLayout = ({ children }) => {
    const location = useLocation();

    return (
        <div className="grid grid-cols-12">
            <div className="col-span-12 sticky top-0 z-50">
                {(location.pathname !== "/security-pass") &&
                    <Header />
                }
            </div>
            <div className="col-span-12 w-full flex">
                {(location.pathname !== "/help-mode") &&
                    <div className="w-[10%] h-screen overflow-y-scroll bg-[#640D5F] hidden lg:block z-10">
                        {(location.pathname !== "/login" && location.pathname !== "/signup" && location.pathname !== "/forgot-pass") &&
                            < SideMenu />
                        }
                        {(location.pathname === "/login" || location.pathname === "/signup" || location.pathname === "/forgot-pass") &&
                            <SideMenuNonClickable />
                        }
                    </div>
                }
                <div className="w-[90%] px-3">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default AppLayout;