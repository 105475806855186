import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { motion, AnimatePresence } from "framer-motion";

import home from "../assets/sidemenuimg/house.png";
import settings from "../assets/sidemenuimg/settings.png";
import team_up from "../assets/sidemenuimg/team_up.png";
import your_bidding from "../assets/sidemenuimg/your_bidding.png";
import financials from "../assets/sidemenuimg/financials.png";
import objectives from "../assets/sidemenuimg/objectives.png";
import performance from "../assets/sidemenuimg/performance.png";
import tier_system from "../assets/sidemenuimg/tier_system.png";
import cent_bidding from "../assets/sidemenuimg/1_cent_bidding.png";

import team from "../assets/sidemenuimg/team.png";
import teamup_search from "../assets/sidemenuimg/teamup-search.png";
import popular_teamup from "../assets/sidemenuimg/popular-teamup.png";
import teamup_message from "../assets/sidemenuimg/teamup-messages.png";
import teamup_application from "../assets/sidemenuimg/teamup-application.png";
import teamup_current from "../assets/sidemenuimg/teamup-current.png";
import teamup_filter from "../assets/sidemenuimg/teamup-filter.png";
import teamup_profile from "../assets/sidemenuimg/teamup-profile.png";
import previous_bid from "../assets/sidemenuimg/previous_bid.png";
import how_it_work from "../assets/sidemenuimg/how_it_work.png";

import kv from "../assets/sidemenuimg/kv.png";
import advert_collection from "../assets/sidemenuimg/advert_collection.png";
import your_advert from "../assets/sidemenuimg/your_advert.png";
import active_advert from "../assets/sidemenuimg/active_advert.png";
import draft_advert from "../assets/sidemenuimg/draft_advert.png";
import quickcreate_advert from "../assets/sidemenuimg/quickcreate_advert.png";
import advert_create from "../assets/sidemenuimg/advert_create.png";
import inactive_advert from "../assets/sidemenuimg/inactive_advert.png";

import higher_tier from "../assets/sidemenuimg/higher_tier.png";
import your_tier from "../assets/sidemenuimg/your_tier.png";
import tier_application from "../assets/sidemenuimg/tier_application.png";

import your_settings from "../assets/sidemenuimg/your_settings.png";
import keyword from "../assets/sidemenuimg/keyword.png";
import account from "../assets/sidemenuimg/account.png";
import edit_account from "../assets/sidemenuimg/edit_account.png";
import delete_account from "../assets/sidemenuimg/delete_account.png";
import trending_advert from "../assets/sidemenuimg/trending_advert.png";

import billing_invoice from "../assets/sidemenuimg/billing_invoice.png";
import privacy_policy from "../assets/sidemenuimg/privacy_policy.png";
import cookies_policy from "../assets/sidemenuimg/cookies_policy.png";
import terms_and_conditions from "../assets/sidemenuimg/terms_and_conditions.png";
import contact from "../assets/sidemenuimg/contact.png";
import contact_expert from "../assets/sidemenuimg/contact_expert.png";

const SideMenuNonClickable = () => {
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);

    const menuItems = [
        { icon: home, name: "Home" },
        {
            icon: your_bidding,
            name: "Bidding",
            subItems: [
                { icon: cent_bidding, name: "1 Cent Bidding" },
                { icon: advert_collection, name: "Add Collections" },
                { icon: previous_bid, name: "Previous Bid" },
                { icon: how_it_work, name: "How 1 Cent Bidding Works" }
            ]
        },
        {
            icon: your_advert,
            name: "Your Adverts",
            subItems: [
                { icon: advert_create, name: "Create Adverts" },
                { icon: active_advert, name: "Active Adverts" },
                { icon: kv, name: "KV Adverts" },
                { icon: inactive_advert, name: "Inactive Adverts" },
                { icon: draft_advert, name: "Drafted Adverts" },
                { icon: quickcreate_advert, name: "Quick Create" }
            ]
        },
        {
            icon: tier_system,
            name: "Tier System",
            subItems: [
                { icon: your_tier, name: "Your Tier" },
                { icon: higher_tier, name: "Higher Tier Advertisers" },
                { icon: tier_application, name: "Tier Increase Application" },
                { icon: how_it_work, name: "How Tier System Works" }
            ]
        },
        {
            icon: team,
            name: "Your Team Up",
            subItems: [
                { icon: team_up, name: "Team Up" },
                { icon: teamup_search, name: "Team Up Search" },
                { icon: teamup_current, name: "Your Current Team Ups" },
                { icon: popular_teamup, name: "Popular Team Up Advertisers" },
                { icon: teamup_filter, name: "Team Up Filters" },
                { icon: teamup_application, name: "Team Up Application" },
                { icon: teamup_profile, name: "Team Up Profile" },
                { icon: teamup_message, name: "Team Up Messages" },
                { icon: how_it_work, name: "How Team Up Works" }
            ]
        },
        { icon: performance, name: "Performance" },
        { icon: financials, name: "Financials" },
        { icon: objectives, name: "Objectives" },
        {
            icon: settings,
            name: "Settings",
            subItems: [
                { icon: your_settings, name: "Your Settings" },
                { icon: keyword, name: "Your Keywords" },
                { icon: account, name: "Your Account" },
                { icon: edit_account, name: "Edit Account" },
                { icon: delete_account, name: "Delete Account" },
                { icon: trending_advert, name: "Trending Adverts" },
                { icon: billing_invoice, name: "Billing and Invoice" },
                { icon: privacy_policy, name: "Privacy Policy" },
                { icon: cookies_policy, name: "Cookies Policy" },
                { icon: terms_and_conditions, name: "Terms And Conditions" },
                { icon: contact, name: "Contact Us" },
                { icon: contact_expert, name: "Contact Expert" },
                { icon: how_it_work, name: "How It Works" },
            ]
        }
    ];

    const toggleDropdown = (index) => {
        setOpenDropdownIndex(openDropdownIndex === index ? null : index);
    };

    const handleItemClick = () => {
        setOpenDropdownIndex(null);
    };

    return (
        <div className="flex flex-col items-center mt-3 ml-5">
            <ul>
                {menuItems.map((item, index) => (
                    item.subItems ? (
                        <li key={index} className="flex flex-col">
                            <div className="flex items-center gap-x-1">
                                <div
                                    className={`h-[6rem] w-[6rem] p-5 flex flex-col items-center justify-center rounded-full bg-[#ffffff] hover:bg-gray-200 mb-3 cursor-pointer`}
                                    onClick={() => toggleDropdown(index)}
                                >
                                    <img
                                        src={item.icon}
                                        alt={item.name}
                                        className="h-[2.5rem] w-[2.5rem] mb-1"
                                    />
                                    <p className="text-[12px] text-[#000000] font-semibold leading-[12px] text-center">
                                        {item.name}
                                    </p>

                                </div>
                                <motion.div
                                    className="ml-auto cursor-pointer"
                                    onClick={() => toggleDropdown(index)}
                                    animate={{ rotate: openDropdownIndex === index ? 180 : 0 }}
                                >
                                    <IoIosArrowDown className="text-[18px] text-white" />
                                </motion.div>
                            </div>
                            <AnimatePresence>
                                {openDropdownIndex === index && (
                                    <motion.ul
                                        initial={{ height: 0, opacity: 0 }}
                                        animate={{ height: "auto", opacity: 1 }}
                                        exit={{ height: 0, opacity: 0 }}
                                        transition={{ duration: 0.3 }}
                                        className="pl-0 mb-1"
                                    >
                                        {item.subItems.map((subItem, subIndex) => (
                                            <li
                                                key={subIndex}
                                                className={`h-[5rem] w-[5rem] p-2 ml-2 flex flex-col items-center justify-center rounded-sm bg-[#ffffff] mb-2 cursor-pointer`}
                                            >
                                                <img
                                                    src={subItem.icon}
                                                    alt={subItem.name}
                                                    className={`h-[2rem] w-[2rem] mb-1`}
                                                />
                                                <p className={`text-[10.5px] font-semibold leading-[12px] text-center text-black`}>
                                                    {subItem.name}
                                                </p>
                                            </li>
                                        ))}
                                    </motion.ul>
                                )}
                            </AnimatePresence>
                        </li>
                    ) : (
                        <li
                            key={index}
                            onClick={() => handleItemClick(item.name)}
                            className={`h-[6rem] w-[6rem] p-5 flex flex-col items-center justify-center rounded-full bg-[#ffffff] mb-3 cursor-pointer`}
                        >
                            <img
                                src={item.icon}
                                alt={item.name}
                                className={`h-[2.5rem] w-[2.5rem] mb-1`}
                            />
                            <p className={`text-[12px] font-semibold leading-[12px] text-center text-black`}>
                                {item.name}
                            </p>
                        </li>
                    )
                ))}
            </ul>
        </div>
    );
};

export default SideMenuNonClickable;