import React from "react";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { businessSpecificData, yourLocalAdvertisersData } from "../../data";

const SimilarAdvertisers = () => {
    const navigate = useNavigate();

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p
                        onClick={() => navigate("/tier-system")}
                        className="text-[#828282] cursor-pointer"
                    >
                        Tier System
                    </p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Similar Advertisers</p>
                </div>

                {/* main content */}
                <p className="text-center text-[22px] font-bold mt-4 underline underline-offset-[5px]">
                    Similar Advertisers
                </p>

                <div className="mt-10">
                    <div className="flex items-center justify-between">
                        <p className="px-4 py-[5px] bg-[#D9D9D9] rounded-md whitespace-nowrap cursor-pointer font-bold text-[13px] w-fit">
                            Online
                        </p>

                        <p
                            onClick={() => navigate("/tier-system/similar-advertiser")}
                            className="px-4 py-[5px] bg-[#000000] text-white rounded-md whitespace-nowrap cursor-pointer font-bold text-[13px] w-fit"
                        >
                            view all
                        </p>
                    </div>

                    <div className="grid grid-cols-12 gap-5 mt-3">
                        {businessSpecificData.map((data) => (
                            <div className="col-span-3 border border-black" key={data.id}>
                                <p className="text-[18px] font-bold border-b border-black p-3">
                                    {data.name}
                                </p>
                                <div className="p-3">
                                    <p className="text-[14px] font-bold text-[#626262]">{data.status}</p>
                                    <p className="text-[14px] font-bold text-[#626262] mt-2">About :</p>
                                    <div className="bg-[#D9D9D9] p-3 overflow-y-scroll h-[8rem] mt-1">
                                        <p className="text-[14px]">
                                            {data.about}
                                        </p>
                                    </div>
                                    <div className="flex items-center gap-x-4 mt-2">
                                        <p className="text-[14px] font-bold text-[#626262]">Location</p>
                                        <p className="text-[14px] font-normal text-[#626262]">{data.location}</p>
                                    </div>
                                </div>
                                <div className="flex items-center justify-center mt-2 mb-8">
                                    <p
                                        onClick={() => navigate(`/team-up/application/view/${data.id}`)}
                                        className="text-[14px] text-[#ffffff] font-bold bg-[#00AB07] px-8 py-2 rounded-md cursor-pointer"
                                    >
                                        Team - UP
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="mt-10">
                    <div className="flex items-center justify-between">
                        <p className="px-4 py-[5px] bg-[#D9D9D9] rounded-md whitespace-nowrap cursor-pointer font-bold text-[13px] w-fit">
                            Physical Stores
                        </p>

                        <p
                            onClick={() => navigate("/tier-system/similar-advertiser")}
                            className="px-4 py-[5px] bg-[#000000] text-white rounded-md whitespace-nowrap cursor-pointer font-bold text-[13px] w-fit"
                        >
                            view all
                        </p>
                    </div>

                    <div className="grid grid-cols-12 gap-5 mt-3">
                        {yourLocalAdvertisersData.map((data) => (
                            <div className="col-span-3 border border-black" key={data.id}>
                                <p className="text-[18px] font-bold border-b border-black p-3">
                                    {data.name}
                                </p>
                                <div className="p-3">
                                    <p className="text-[14px] font-bold text-[#626262]">{data.status}</p>
                                    <p className="text-[14px] font-bold text-[#626262] mt-2">About :</p>
                                    <div className="bg-[#D9D9D9] p-3 overflow-y-scroll h-[8rem] mt-1">
                                        <p className="text-[14px]">
                                            {data.about}
                                        </p>
                                    </div>
                                    <div className="flex items-center gap-x-4 mt-2">
                                        <p className="text-[14px] font-bold text-[#626262]">Location</p>
                                        <p className="text-[14px] font-normal text-[#626262]">{data.location}</p>
                                    </div>
                                </div>
                                <div className="flex items-center justify-center mt-2 mb-8">
                                    <p
                                        onClick={() => navigate(`/team-up/application/view/${data.id}`)}
                                        className="text-[14px] text-[#ffffff] font-bold bg-[#00AB07] px-8 py-2 rounded-md cursor-pointer"
                                    >
                                        Team - UP
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SimilarAdvertisers