import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";

const ForgotPass = () => {
    const navigate = useNavigate();
    const [steps, setSteps] = useState(1);
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [email, setEmail] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [timer, setTimer] = useState(120); // 2 minutes in seconds

    useEffect(() => {
        if (steps === 2 && timer > 0) {
            const countdown = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
            return () => clearInterval(countdown);
        } else if (steps === 2 && timer === 0) {
            toast.error("OTP verification time has expired. Please request a new OTP.");
        }
    }, [steps, timer]);

    const handleResetPassword = () => {
        if (!email) {
            toast.error("Please enter your email address.");
            return;
        }
        toast.success("A new OTP has been sent to you. Please check your email.");
        setTimeout(() => {
            setSteps(2);
            setTimer(120); // Reset timer to 2 minutes
        }, 3000);
    };

    const verifyOtp = () => {
        toast.success("OTP has been verified successfully.");
        setTimeout(() => {
            setSteps(3);
        }, 3000);
    };

    const resetPassword = () => {
        if (newPassword !== confirmPassword) {
            toast.error("Passwords do not match.");
            return;
        }
        toast.success("Password has been reset successfully.");
        setTimeout(() => {
            navigate("/");
        }, 3000);
    };

    const handleOtpChange = (element, index) => {
        if (isNaN(element.value)) return false;
        const newOtp = [...otp.map((d, idx) => (idx === index ? element.value : d))];
        setOtp(newOtp);

        // Focus on next input
        if (element.nextSibling) {
            element.nextSibling.focus();
        }

        // Auto-submit if all digits are entered
        if (newOtp.every((digit) => digit !== "")) {
            verifyOtp();
        }
    };

    const handleResendOTP = () => {
        toast.success("A new OTP has been sent to your email");
        setTimer(120); // Reset timer to 2 minutes
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    return (
        <Fragment>
            <Toaster position="top-right" reverseOrder={false} />
            <div className="grid grid-cols-12 h-screen overflow-hidden">
                <div className="col-span-12 pt-[2rem]">
                    <p className="flex justify-center text-[22px] font-bold">
                        Forgot Password
                    </p>
                    <div className="flex justify-center pt-[1rem]">
                        <div className="w-[30rem] border border-gray-400 bg-[#f4f4f4] rounded-xl p-5 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]">
                            {steps === 1 && (
                                <div className="flex flex-col gap-y-3">
                                    <div className="grid grid-cols-12 gap-4 items-center">
                                        <div className="col-span-12">
                                            <p className="text-[16px]">
                                                Enter your email address below and we'll send you an OTP to reset your password.
                                            </p>
                                        </div>
                                        <div className="col-span-12">
                                            <input
                                                type="text"
                                                placeholder="Enter your email address"
                                                className="w-full px-2 py-2 border border-gray-400 rounded-lg focus:ring-1 focus:ring-[#00AB07] focus:outline-none"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex justify-between items-center pt-5 pb-2">
                                        <button
                                            onClick={() => navigate(-1)}
                                            className="w-[10rem] py-2 bg-[#000000] hover:bg-opacity-90 text-white rounded-lg"
                                        >
                                            Go Back
                                        </button>
                                        <button
                                            onClick={handleResetPassword}
                                            className="w-[10rem] py-2 bg-[#00AB07] hover:bg-opacity-90 text-white rounded-lg"
                                        >
                                            Send OTP
                                        </button>
                                    </div>
                                </div>
                            )}

                            {steps === 2 && (
                                <div className="flex flex-col gap-y-3">
                                    <div className="grid grid-cols-12 gap-4 items-center">
                                        <div className="col-span-12">
                                            <p className="text-[16px]">
                                                Enter the OTP sent to your email address below.
                                            </p>
                                        </div>
                                        <div className="col-span-12 flex justify-center gap-2">
                                            {otp.map((data, index) => {
                                                return (
                                                    <input
                                                        key={index}
                                                        type="text"
                                                        maxLength="1"
                                                        className="w-10 px-2 py-2 border border-gray-400 rounded-lg focus:ring-1 focus:ring-[#00AB07] focus:outline-none text-center"
                                                        value={data}
                                                        onChange={(e) => handleOtpChange(e.target, index)}
                                                        onFocus={(e) => e.target.select()}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-center gap-x-2">
                                        <p className="text-center text-[#640D5F]">{formatTime(timer)}</p>
                                    </div>
                                    <div className="flex items-center justify-end mt-[-2.2rem]">
                                        <p
                                            onClick={handleResendOTP}
                                            className="text-blue-500 hover:text-blue-600 hover:underline w-fit cursor-pointer"
                                        >
                                            Resend OTP
                                        </p>
                                    </div>
                                    <div className="flex justify-between items-center pt-5 pb-2">
                                        <button
                                            onClick={() => setSteps(1)}
                                            className="w-[10rem] py-2 bg-[#000000] hover:bg-opacity-90 text-white rounded-lg"
                                        >
                                            Change Email
                                        </button>
                                        <button
                                            onClick={verifyOtp}
                                            className="w-[10rem] py-2 bg-[#00AB07] hover:bg-opacity-90 text-white rounded-lg"
                                        >
                                            Verify OTP
                                        </button>
                                    </div>
                                </div>
                            )}

                            {steps === 3 && (
                                <div className="flex flex-col gap-y-3">
                                    <div className="grid grid-cols-12 gap-4 items-center">
                                        <div className="col-span-12">
                                            <p className="text-[16px]">
                                                Enter your new password below.
                                            </p>
                                        </div>
                                        <div className="col-span-12 relative">
                                            <input
                                                type={showPassword ? "text" : "password"}
                                                placeholder="Enter your new password"
                                                className="w-full pl-2 pr-10 py-2 border border-gray-400 rounded-lg focus:ring-1 focus:ring-[#00AB07] focus:outline-none"
                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                            />
                                            <div
                                                className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? <IoEyeOffOutline className="text-[20px]" /> : <IoEyeOutline className="text-[20px]" />}
                                            </div>
                                        </div>
                                        <div className="col-span-12 relative">
                                            <input
                                                type={showConfirmPassword ? "text" : "password"}
                                                placeholder="Confirm your new password"
                                                className="w-full pl-2 pr-10 py-2 border border-gray-400 rounded-lg focus:ring-1 focus:ring-[#00AB07] focus:outline-none"
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                            />
                                            <div
                                                className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                            >
                                                {showConfirmPassword ? <IoEyeOffOutline className="text-[20px]" /> : <IoEyeOutline className="text-[20px]" />}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex justify-between items-center pt-5 pb-2">
                                        <button
                                            onClick={() => setSteps(2)}
                                            className="w-[10rem] py-2 bg-[#000000] hover:bg-opacity-90 text-white rounded-lg"
                                        >
                                            Go Back
                                        </button>
                                        <button
                                            onClick={resetPassword}
                                            className="w-[10rem] py-2 bg-[#00AB07] hover:bg-opacity-90 text-white rounded-lg"
                                        >
                                            Reset Password
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ForgotPass;