import React, { useState } from "react";
import { GoHomeFill } from "react-icons/go";
import { motion } from "framer-motion";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { yourAdvertData } from "../../data";

const InactiveAdvert = () => {
    const navigate = useNavigate();
    const [advertType, setAdvertType] = useState("");

    const animationSettings = {
        initial: { opacity: 0, y: -20 },
        animate: { opacity: 1, y: 0, transition: { duration: 0.5, ease: "easeOut" } },
    };

    const cardVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } },
    };

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2,
                delayChildren: 0.1,
                ease: "easeOut",
            },
        },
    };

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p
                        onClick={() => navigate("/your-advert")}
                        className="text-[#828282] cursor-pointer"
                    >
                        Your Adverts
                    </p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Inactive Adverts</p>
                </div>

                {/* main content */}
                <motion.div
                    {...animationSettings}
                    className="flex justify-between mt-5"
                >
                    <p className="text-[24px] font-bold text-[#333]">
                        Inactive Ads On Display
                    </p>
                    <div className="flex items-center gap-x-3">
                        <p className="text-[#626262] text-[14px] font-bold">Advert Type :</p>
                        <div className="relative">
                            <select
                                required
                                value={advertType}
                                onChange={(e) => setAdvertType(e.target.value)}
                                className="w-[10rem] bg-[#000000] px-3 py-[6px] text-white text-[14px] border border-[#ffffff] focus:outline-none appearance-none rounded"
                            >
                                <option value={""}>Select</option>
                                <option value="Stacic Advert">Stacic Advert</option>
                                <option value="Video Advert">Video Advert</option>
                                <option value="Pop-Up Advert">Pop-Up Advert</option>
                            </select>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.2" stroke="currentColor" className="h-4 w-4 ml-1 absolute top-2.5 right-2.5 text-slate-700 text-white">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                            </svg>
                        </div>
                    </div>
                </motion.div>

                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={containerVariants}
                    className="grid grid-cols-12 gap-6 mt-10"
                >
                    {yourAdvertData.map((data) => (
                        <motion.div
                            key={data.id}
                            variants={cardVariants}
                            className="col-span-3 border border-gray-300 bg-white shadow-lg rounded-lg overflow-hidden transform transition-transform hover:scale-105"
                            onClick={() => navigate(`/your-advert/inactive/repost-success/${data.id}`)}
                        >
                            <p className="text-center bg-gray-400 text-[16px] font-semibold py-2">
                                {data.name} Ad
                            </p>
                            <div className="overflow-hidden">
                                <img
                                    alt="advert"
                                    src={data.image}
                                    className="w-full h-[12rem] object-cover transform transition-transform duration-300 hover:scale-110"
                                />
                            </div>
                            <div className="p-4">
                                <p className="text-[14px] text-[#555] text-center">
                                    Stopped advertising : {data.created}
                                </p>
                                <div className="mt-2 border-t border-gray-300 pt-2">
                                    <p className="text-[14px] text-[#555] mb-1">Total Interactions: {data.interactions}</p>
                                    <p className="text-[14px] text-[#555] mb-1">Bidding Cost: {data.cost}</p>
                                    <p className="text-[14px] text-[#555] mb-1">Performance: This advert is in your top 10 adverts for interactions!</p>
                                    <button 
                                        className="text-[14px] bg-[#00AB07] hover:bg-[#00b606] text-[#ffffff] w-full py-[6px] mt-2"
                                    >
                                        Repost
                                    </button>
                                </div>
                            </div>
                        </motion.div>
                    ))}
                </motion.div>
            </div>
        </div>
    )
}

export default InactiveAdvert