import React, { useState } from "react";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";

const AddObjectives = () => {
    const navigate = useNavigate();
    const [objectivesOne, setObjectivesOne] = useState("Increase views by 70%");
    const [objectivesTwo, setObjectivesTwo] = useState("Increase clicks by 5%");
    const [objectivesThree, setObjectivesThree] = useState("Increase impression by 10%");
    const [objectivesFour, setObjectivesFour] = useState("");
    const [objectivesFive, setObjectivesFive] = useState("");

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p onClick={() => navigate("/objectives")} className="text-[#828282] cursor-pointer">Objectives</p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Add Objectives</p>
                </div>

                {/* main content */}
                <p className="text-[20px] font-bold mt-5 text-center underline underline-offset-[5px]">
                    Abb Objectives
                </p>
                <p className="text-[14px] mt-5">
                    Note : You can only set 5 Objectives at one time
                </p>
                <div className="grid grid-cols-12 mt-10">
                    <div className="col-span-6">
                        <p className="text[18px] font-bold mb-2">Current Objectives</p>
                        <p className="text-[16px] mb-2">1. {objectivesOne}</p>
                        <p className="text-[16px] mb-2">2. {objectivesTwo}</p>
                        <p className="text-[16px] mb-2">3. {objectivesThree}</p>
                        <p className="text-[16px] mb-2">4. {objectivesFour}</p>
                        <p className="text-[16px] mb-2">5. {objectivesFive}</p>
                    </div>
                    <div className="col-span-4 w-[80%]">
                        <p className="text[18px] font-bold text-center mb-5">Set Objectives</p>
                        <div className="border border-[#5e5b5b] p-3 rounded">
                            <p className="text-[14px] text-center font-bold mb-3">
                                Type your final two Objectives here!
                            </p>
                            <div className="flex items-center gap-x-3 mb-3">
                                <p>1.</p>
                                <input
                                    type="text"
                                    value={objectivesOne}
                                    onChange={(e) => setObjectivesOne(e.target.value)}
                                    className="w-full border border-[#b0a9a9] py-1 px-4 rounded-full focus:outline-none"
                                />
                            </div>
                            <div className="flex items-center gap-x-3 mb-3">
                                <p>2.</p>
                                <input
                                    type="text"
                                    value={objectivesTwo}
                                    onChange={(e) => setObjectivesTwo(e.target.value)}
                                    className="w-full border border-[#b0a9a9] py-1 px-4 rounded-full focus:outline-none"
                                />
                            </div>
                            <div className="flex items-center gap-x-3 mb-3">
                                <p>3.</p>
                                <input
                                    type="text"
                                    value={objectivesThree}
                                    onChange={(e) => setObjectivesThree(e.target.value)}
                                    className="w-full border border-[#b0a9a9] py-1 px-4 rounded-full focus:outline-none"
                                />
                            </div>
                            <div className="flex items-center gap-x-3 mb-3">
                                <p>4.</p>
                                <input
                                    type="text"
                                    value={objectivesFour}
                                    onChange={(e) => setObjectivesFour(e.target.value)}
                                    className="w-full border border-[#b0a9a9] py-1 px-4 rounded-full focus:outline-none"
                                />
                            </div>
                            <div className="flex items-center gap-x-3 mb-3">
                                <p>5.</p>
                                <input
                                    type="text"
                                    value={objectivesFive}
                                    onChange={(e) => setObjectivesFive(e.target.value)}
                                    className="w-full border border-[#b0a9a9] py-1 px-4 rounded-full focus:outline-none"
                                />
                            </div>
                            <div className="flex items-center justify-center">
                                <button
                                    className="bg-[#0DD315] text-white px-3 py-1 rounded"
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddObjectives